
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  name: "kt-drawer-courseCategoryEdit",
  components: { Field },

  data() {
    return {
      suppliers: [] as any,
      type: '' as any,
      name: "",
      entry_date: "",
      supplier: "",
      file_no: "",
      rate_in: "",
      balance: ''as any,
      value_acquisition: ''as any,
      usefull_of_life: "",
      identification: "",
      package: "",
      location: "",
      opening_balance: ''as any,
      opening_wdv: ''as any,
      charged_period: "",
      wdv_end: ''as any,
      remarks: "",
      formID:"",
      load: false,
      loading: false,
      batch: {
                entity_id: '',
                tranche_id: '',
                institute_id: '',
                course_id: '',
            },
    };
  },
  async created() {
    this.getSupplier();
    this.emitter.on("asset-update-drawer", async (data) => {
      this.formID = data.id;
      this.name = data.asset_name;
      this.entry_date = data.entry_date;
      this.supplier = data.supplier_id;
      this.file_no = data.file_number;
      this.rate_in = data.rate_in;
      this.balance = data.balance_end;
      this.value_acquisition = data.cost;
      this.usefull_of_life = data.useful_life;
      this.identification = data.mark_number;
      this.package = data.package_no;
      this.location = data.location;
      this.opening_balance = data.opening_balance;
      this.opening_wdv = data.opening_wdv;
      this.charged_period = data.charged_period;
      this.wdv_end = data.wdv_end;
      this.remarks = data.verification_remarks;
    });
  },
  methods: {
    calculateWDV() {
      this.balance = this.opening_balance + this.charged_period;
      this.wdv_end = this.value_acquisition - this.balance;
    },

     async getSupplier() {
            let entity_id = '';
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            else{
                institute_info_id = this.batch.institute_id ;
            }
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            else{
                 entity_id = this.batch.entity_id;
            }
            await ApiService.get('supplier/list?entity_id=' + entity_id +
                '&institute_id=' +
                institute_info_id +
                '&type=' +
                this.type
            )
                .then((response) => {
                    this.suppliers = response.data.data;
                    console.log(response);
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
       async formSubmit() {
            let formData = new FormData();
            let user_id = VueCookieNext.getCookie('_seip_employee_id');
          formData.set('formID', this.formID);
         formData.set('name', this.name);
         formData.set('entry_date', this.entry_date);
         formData.set('supplier', this.supplier);
         formData.set('file_no', this.file_no);
         formData.set('rate_in', this.rate_in);
         formData.set('balance', this.balance);
         formData.set('value_acquisition', this.value_acquisition);
         formData.set('usefull_of_life', this.usefull_of_life);
         formData.set('identification', this.identification);
         formData.set('package', this.package);
         formData.set('location', this.location);
          formData.set('opening_balance', this.opening_balance);
         formData.set('opening_wdv', this.opening_wdv);
         formData.set('charged_period', this.charged_period);
         formData.set('wdv_end', this.wdv_end);
         formData.set('remarks', this.remarks);
         formData.set('user_id', user_id);
    
            let route = 'asset_register/update';
            this.loading = true;
            await ApiService.post(route, formData)
                .then((response) => {
                    console.log(response);
                    this.loading = false;
                    if (response.status == 200) {
                        Swal.fire({
                            text: 'Saved Succesfully',
                            icon: 'success',
                            buttonsStyling: false,
                            confirmButtonText: 'Ok',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        }).then(() => {
                              DrawerComponent?.hideAll();
                             this.emitter.emit('trainee-updated', true);
                        });
                    } else {
                        let err = '';
                        for (const field of Object.keys(response.data.errors)) {
                            err += response.data.errors[field][0] + '<br>';
                        }
                        Swal.fire({
                            title: 'Error!',
                            html: err,
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: 'Close',
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                        });
                    }
                })
        },
  },
});
